import React from 'react'
import './navigation.scss'

import Logo from '../../assets/images/ubezpieczeniepracownikow-logo.svg';
import { toggleMenu } from '../../scripts/scripts';
import { Link } from 'gatsby';
import config from '../../../config.json';
import ReferrerLink from '../ReferrerLink/ReferrerLink';

export default function Navigation() {

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container position-relative">
        <a className="navbar-brand" href="/" title="Ubezpieczenie pracowników">
          <img className="navbar-logo" src={Logo} alt="Ubezpieczenie pracowników" title="Ubezpieczenie pracowników" />
        </a>
        <span className="navbar-toggler-icon" onClick={toggleMenu}></span>
        <div className="collapse navbar-collapse" id="top-menu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="/ranking-ubezpieczen/"
                activeClassName="active"
                className="nav-link"
                title="Ranking ubezpieczeń"
              >
                Ranking ubezpieczeń
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/strefa-wiedzy/"
                activeClassName="active"
                className="nav-link"
                title="Strefa wiedzy"
              >
                Strefa wiedzy
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/kontakt/"
                activeClassName="active"
                className="nav-link"
                title="Kontakt"
              >
                Kontakt
              </Link>
            </li>
            <li>
              <ReferrerLink activeClass="compare-btn mobile-compare-btn referrer-link" text="Porównaj" title="Porównaj" />
            </li>
          </ul>
        </div>
        <ReferrerLink activeClass="compare-btn referrer-link" text="Porównaj" title="Porównaj" />
      </div>
    </nav>
  )
}
